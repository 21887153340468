export const validadorCep = {
    valida
};

export function valida(value)
{
  let cep = value.replace(/-/g,'');
  if (cep.length == 8) {
    return /^[0-9]{8}$/.test(cep) && cep !== "99999999";
  }
  
  return false;
}
