export const validadorDataYMD = {
  valida,
};

export function valida(dateString) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString))
    // Verifica o formato "yyyy-mm-dd"
    return false;

  // Parse the date parts to integers
  var parts = dateString.split("-");
  var year = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var day = parseInt(parts[2], 10);

  // Verifica se o ano, mês e dia estão dentro dos limites aceitáveis
  if (year < 1694 || year > 2100 || month === 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Ajusta para anos bissextos
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Verifica se o dia está dentro do intervalo do mês
  return day > 0 && day <= monthLength[month - 1];
}
